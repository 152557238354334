import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Pubnub from 'pubnub';
import store from 'store';

import { apiRequester, handleError } from '../utility';

const GATSBY_GLOBAL_VIDEO_BOOTH_ID = process.env.GATSBY_GLOBAL_VIDEO_BOOTH_ID;
const GATSBY_GLOBAL_VIDEO_MODULE_ID = process.env.GATSBY_GLOBAL_VIDEO_MODULE_ID;

export interface GlobalContextType {
    // User
    user?: Users.User;
    setUser: React.Dispatch<React.SetStateAction<Users.User | undefined>>;

    // Event
    event?: Events.Event | undefined;
    eventModules?: (Modules.Module | undefined)[];
    eventAuthModule?: Modules.Module<Modules.AuthModuleData> | null;
    extraUserProfileFields: Users.ExtraField[];
    fetchEvent: () => Promise<{
        eventAuthModule: Modules.Module<Modules.AuthModuleData> | null | undefined;
        extraUserProfileFields: Users.ExtraField[];
    }>;

    // Booth
    booths?: Booths.Booth[] | undefined;
    booth?: Booths.Booth | undefined;
    boothModules?: (Modules.Module | undefined)[];
    boothChatModules?: (Modules.Module<Modules.ChatModuleData> | undefined)[];
    liveStreamingModules?: (Modules.Module<Modules.LiveStreamingData> | undefined)[];
    boothChats?: (Modules.Chat | undefined)[];
    boothVideoCallModules?: Modules.Module[];
    boothVideoCallRequests?: (Modules.VideoCallRequest | undefined)[];
    boothFileDownloadModules?: (Modules.Module<Modules.FileDownloadsData> | undefined)[];
    selectBooth: ({
        boothId,
    }: {
        boothId?: string;
    }) => Promise<{ booth?: Booths.Booth; boothModules: Modules.Module[] }>;
    refreshVideoCallRequests: () => Promise<{ videoCallRequests: Modules.VideoCallRequest[] }>;

    // User List
    userList: { [id: string]: Users.User };
    setUserList: Dispatch<SetStateAction<{ [id: string]: Users.User }>>;

    // Rooms
    scheduledRooms: Modules.VideoRoom[];
    refreshScheduledRooms: () => Promise<Modules.VideoRoom[]>;

    // Global Pubnub
    pubnub?: Pubnub;
    setPubnub: (pubnub: Pubnub) => void;
    channels: string[];
    oldChannels: string[];
    setChannels: React.Dispatch<React.SetStateAction<string[]>>;
    setOldChannels: React.Dispatch<React.SetStateAction<string[]>>;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    // User
    user: undefined,
    setUser: () => {},

    // Event
    event: undefined,
    eventModules: [],
    eventAuthModule: undefined,
    extraUserProfileFields: [],
    fetchEvent: () => Promise.resolve({ eventAuthModule: undefined, extraUserProfileFields: [] }),

    // Booth
    booths: [],
    booth: undefined,
    boothModules: [],
    boothChatModules: [],
    boothChats: [],
    boothVideoCallModules: [],
    boothVideoCallRequests: [],
    boothFileDownloadModules: [],
    selectBooth: ({ boothId }: { boothId?: string }) => Promise.resolve({ booth: {}, boothModules: [] }),
    refreshVideoCallRequests: () => Promise.resolve({ videoCallRequests: [] }),

    // User list
    userList: {},
    setUserList: () => {},

    // Rooms
    scheduledRooms: [],
    refreshScheduledRooms: () => Promise.resolve([]),

    // Global Pubnub
    setPubnub: () => {},
    channels: [],
    oldChannels: [],
    setChannels: () => ([] as never) as React.Dispatch<React.SetStateAction<string[]>>,
    setOldChannels: () => ([] as never) as React.Dispatch<React.SetStateAction<string[]>>,
});

const CHAT_SUB_KEY = process.env.GATSBY_CHAT_SUB_KEY!;
const CHAT_PUB_KEY = process.env.GATSBY_CHAT_PUB_KEY!;

const GlobalContextProvider = (props: { children: React.ReactChildren }) => {
    const [user, setUser] = useState<Users.User>();
    const [event, setEvent] = useState<Events.Event>();
    const [eventModules, setEventModules] = useState<Modules.Module[]>([]);
    const [extraUserProfileFields, setExtraUserProfileFields] = useState<Users.ExtraField[]>([]);
    const [booth, setBooth] = useState<Booths.Booth>();
    const [booths, setBooths] = useState<Booths.Booth[]>([]);
    const [boothModules, setBoothModules] = useState<Modules.Module[]>([]);
    const [boothChatModules, setBoothChatModules] = useState<Modules.Module<Modules.ChatModuleData>[]>([]);
    const [boothChats, setBoothChats] = useState<Modules.Chat[] | undefined>([]);
    const [boothVideoCallModules, setBoothVideoCallModules] = useState<Modules.Module[]>([]);
    const [boothFileDownloadModules, setBoothFileDownloadModules] = useState<
        Modules.Module<Modules.FileDownloadsData>[]
    >([]);
    const [boothVideoCallRequests, setBoothVideoCallRequests] = useState<Modules.VideoCallRequest[]>([]);
    const [eventAuthModule, setEventAuthModule] = useState<Modules.Module<Modules.AuthModuleData> | null | undefined>(
        undefined,
    );
    const [userList, setUserList] = useState<{ [id: string]: Users.User }>({});
    const [scheduledRooms, setScheduledRooms] = useState<Modules.VideoRoom[]>([]);
    const [liveStreamingModules, setLiveStreamingModules] = useState<Modules.Module<Modules.LiveStreamingData>[]>([]);
    const [channels, setChannels] = useState<string[]>([]);
    const [oldChannels, setOldChannels] = useState<string[]>([]);
    const [pubnub, setPubnub] = useState<Pubnub>();

    useEffect(() => {
        if (user) {
            const pubnub = new Pubnub({
                publishKey: CHAT_PUB_KEY,
                subscribeKey: CHAT_SUB_KEY,
                restore: true,
                autoNetworkDetection: true,
                listenToBrowserNetworkEvents: true,
                keepAliveSettings: {
                    timeout: 10000,
                },
                uuid: user._id,
                authKey: user.chatAuthToken,
            });
            setPubnub(pubnub);
        }
    }, [user]);

    useEffect(() => {
        if (channels && channels.length) {
            console.info('Subscribing to channels', channels);
            pubnub?.subscribe({ channels, withPresence: true });
        }

        const x = setInterval(() => {
            const userId = user?._id;
            if (userId) {
                pubnub?.whereNow({ uuid: userId }).then(response => {
                    const subscribedChannels = response.channels;
                    console.log({
                        currentlySubscribedBoot: subscribedChannels,
                        boothsToActuallyBeSubscribedTo: channels,
                    });
                    if (channels && channels.length) {
                        const channelsNotYetSubscribed = channels.filter(
                            channel => !channel.includes('pnpres') && !subscribedChannels.includes(channel),
                        );
                        if (channelsNotYetSubscribed.length) {
                            console.info('Subscribing to channels', channels);
                            pubnub?.subscribe({ channels: channels, withPresence: true });
                        }
                    }
                });
            }
        }, 10000);

        return () => clearInterval(x);
    }, [channels, user]);

    useEffect(() => {
        if (oldChannels && oldChannels.length) {
            console.info('Un-subscribing from channels', oldChannels);
            pubnub?.unsubscribe({ channels: oldChannels });
        }
    }, [oldChannels]);

    const refreshScheduledRooms = async () => {
        try {
            const rooms = await apiRequester.getScheduledRooms();
            setScheduledRooms(rooms);
            return rooms;
        } catch (err) {
            handleError(err);
            return Promise.resolve([]);
        }
    };

    const fetchEvent = async () => {
        const [event, eventModules, extraUserProfileFields, booths] = await Promise.all([
            apiRequester.getEvent(),
            apiRequester.getEventModules(),
            apiRequester.getUserExtraProfileFields(),
            apiRequester.getAllBooths(),
        ]);
        const eventAuthModule = eventModules.find(module => module.type === 'authentication') as Modules.Module<
            Modules.AuthModuleData
        >;
        setBooths(booths);
        setEvent(event);
        setEventModules(eventModules);
        setEventAuthModule(typeof eventAuthModule === 'undefined' ? null : eventAuthModule);
        store.set('eventAuthModule', eventAuthModule?._id ? eventAuthModule?._id : null);
        setExtraUserProfileFields(extraUserProfileFields);
        return {
            event,
            eventModules,
            eventAuthModule: eventAuthModule?._id ? eventAuthModule : null,
            extraUserProfileFields,
        };
    };

    const refreshChats = async ({ boothId, modules }: { boothId: string; modules: Modules.Module[] }) => {
        if (!modules || !modules.length) {
            setBoothChats(undefined);
            setBoothChats([]);
        } else {
            let chats = await apiRequester.getChats({ boothId, moduleId: modules[0]._id! });
            if (!chats || !chats.length) await apiRequester.createChat({ boothId, moduleId: modules[0]._id! });
            chats = await apiRequester.getChats({ boothId, moduleId: modules[0]._id! });
            setBoothChats(chats);
        }
    };

    const refreshVideoCallRequests = async () => {
        const videoCalls = booth
            ? await apiRequester.getBootVideoCallRequests({
                  boothId: booth?._id!,
              })
            : [];
        if (GATSBY_GLOBAL_VIDEO_BOOTH_ID && GATSBY_GLOBAL_VIDEO_MODULE_ID) {
            const globalVideoCallModuleRequests = await apiRequester.getBootVideoCallRequests({
                boothId: GATSBY_GLOBAL_VIDEO_BOOTH_ID,
            });
            videoCalls.push(...globalVideoCallModuleRequests);
        }
        setBoothVideoCallRequests(videoCalls);
        return { videoCallRequests: videoCalls };
    };

    const selectBooth = async ({ boothId }: { boothId?: string }) => {
        if (boothId) {
            let boothList = booths;
            if (!boothList || !boothList.length) boothList = await apiRequester.getAllBooths();
            const booth = boothList.find(booth => booth._id === boothId);
            const boothModules = await apiRequester.getBoothModules({ boothId });
            const chatModules = boothModules.filter(module => module.type === 'chat') as Modules.Module<
                Modules.ChatModuleData
            >[];

            const videoCallModules = boothModules.filter(module => module.type === 'video-call') as Modules.Module[];
            if (GATSBY_GLOBAL_VIDEO_BOOTH_ID && GATSBY_GLOBAL_VIDEO_MODULE_ID) {
                videoCallModules.push({
                    _id: GATSBY_GLOBAL_VIDEO_MODULE_ID,
                    booth: { _id: GATSBY_GLOBAL_VIDEO_BOOTH_ID },
                    target: 'booth',
                    type: 'video-call',
                });
            }

            const liveStreamingModules = boothModules.filter(
                module => module.type === 'live-streaming',
            ) as Modules.Module<Modules.LiveStreamingData>[];
            const fileDownloadModules = boothModules.filter(
                module => module.type === 'file-downloads',
            ) as Modules.Module<Modules.FileDownloadsData>[];

            const videoCallRequests = await Promise.all(
                videoCallModules.map(module => apiRequester.getBootVideoCallRequests({ boothId })),
            );
            if (GATSBY_GLOBAL_VIDEO_BOOTH_ID && GATSBY_GLOBAL_VIDEO_MODULE_ID) {
                const globalVideoCallModuleRequests = await apiRequester.getBootVideoCallRequests({
                    boothId: GATSBY_GLOBAL_VIDEO_BOOTH_ID,
                });
                videoCallRequests.push(globalVideoCallModuleRequests);
            }

            setBoothVideoCallRequests(videoCallRequests.flat());
            setBooth(booth);
            setBoothModules(boothModules);
            setBoothChatModules(chatModules);
            setBoothVideoCallModules(videoCallModules);
            setBoothFileDownloadModules(fileDownloadModules);
            setLiveStreamingModules(liveStreamingModules);
            refreshChats({ boothId, modules: chatModules });
            return { booth: booth, boothModules };
        } else {
            setBooth(undefined);
            setBoothVideoCallRequests([]);
            setBoothModules([]);
            setBoothChatModules([]);
            setBoothVideoCallModules([]);
            setBoothFileDownloadModules([]);
            setBoothChats(undefined);
            return { booth: { _id: boothId }, boothModules: [] };
        }
    };

    const globalContext: GlobalContextType = {
        user,
        setUser,
        event,
        eventModules,
        eventAuthModule,
        extraUserProfileFields,
        fetchEvent,
        booths,
        booth,
        boothModules,
        boothChatModules,
        boothChats,
        boothVideoCallModules,
        boothVideoCallRequests,
        selectBooth,
        refreshVideoCallRequests,
        userList,
        setUserList,
        scheduledRooms,
        refreshScheduledRooms,
        liveStreamingModules,
        channels,
        pubnub,
        setPubnub,
        setChannels,
        oldChannels,
        setOldChannels,
    };

    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};

const wrapWithProvider = ({ element }: { element: React.ReactChildren }) => (
    <GlobalContextProvider>{element}</GlobalContextProvider>
);

export default wrapWithProvider;
